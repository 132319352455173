export const contactsStatic = {
  crumbsNav: [
    {
      id: 1,
      link: '/',
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
    },
    {
      id: 2,
      link: '/contacts/',
      title_ua: 'Контакти',
      title_ru: 'Контакты',
      title_en: 'Contacts',
    },
  ],
};
